<template>
  <div class="login-form login-signup">
    <form
      class="form"
      novalidate="novalidate"
      id="kt_login_signup_form"
    >
      <div class="pb-13 pt-lg-0 pt-5">
        <h3
          class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
        >
          Sign Up
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          Enter your details to create your account
        </p>
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Firstname"
          name="firstname"
          v-model="form.firstname"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="text"
          placeholder="Lastname"
          name="lastname"
          v-model="form.lastname"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="email"
          placeholder="Email"
          name="email"
          v-model="form.email"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="password"
          placeholder="Password"
          name="password"
          v-model="form.password"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="password"
          placeholder="Confirm password"
          name="cpassword"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label class="checkbox mb-0">
          <input type="checkbox" name="agree" />
          <span class="mr-2"></span>
          I Agree the
          <a href="#" class="ml-2">terms and conditions</a>.
        </label>
      </div>
      <AuthErrors />
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          ref="kt_login_signup_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
          style="width:150px;"
        >
          Submit
        </button>
        <button
          type="button"
          id="kt_login_signup_cancel"
          class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import AuthErrors from '@/view/components/Auth/AuthErrors';
import { mapGetters, mapState } from "vuex";
import { LOGOUT, REGISTER, SET_ERROR } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "AuthSignUp",
  components: {
    AuthErrors
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        firstname: null,
        lastname: null
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    const signup_form = KTUtil.getById("kt_login_signup_form");

    this.$store.dispatch(LOGOUT);

    this.fv = formValidation(signup_form, {
      fields: {
        firstname: {
          validators: {
            notEmpty: {
              message: "First name is required"
            }
          }
        },
        lastname: {
          validators: {
            notEmpty: {
              message: "Last name is required"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(REGISTER, this.form)
          .then(() => this.$router.push({ name: "dashboard" }));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        heightAuto: false
      });
    });
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'login' })
    }
  }
};
</script>
