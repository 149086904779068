<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signup-on"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <AuthSignUp />
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import AuthSignUp from '@/view/components/Auth/AuthSignUp';

import KTUtil from "@/assets/js/components/util";
import { SET_ERROR } from "@/core/services/store/auth.module";

export default {
  name: "SignUp",
  components: {
    AuthSignUp
  }
};
</script>

<!-- Load signup custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<style scoped>
.text-primary {
  cursor: pointer;
}

input[name="referralEmail"].is-valid.without-value {
  background-image: none;
}
</style>
